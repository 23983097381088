<template>
  <div class="mt-5 d-flex justify-space-between">
    <div class="d-block">
      <div class="mb-1">ETC</div>
      <v-avatar :color="task.ETC_card ? '#4F55A7' : '#DEDEDE'" size="41">
        <span
          class="text-caption"
          :class="task.ETC_card ? 'white--text' : 'grey--text'"
        >
          {{ task.ETC_card ? "有" : "無" }}
        </span>
      </v-avatar>
    </div>
    <div class="d-block pr-10">
      <div class="mb-1">ガソリンカード</div>
      <v-avatar :color="task.gasoline_card ? '#4F55A7' : '#DEDEDE'" size="41">
        <span
          class="text-caption"
          :class="task.gasoline_card ? 'white--text' : 'grey--text'"
        >
          {{ task.gasoline_card ? "有" : "無" }}
        </span>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Gasoline",
  computed: {
    ...mapGetters(["task"])
  }
};
</script>

<style></style>
