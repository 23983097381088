<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="text-center pb-0">
            <img
              :src="preview_file"
              width="100%"
              height="100%"
              class="image_preview mb-2"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ShowPhotoDialog",
  data() {
    return {
      descriptionMaxLength: 500,
      modal: {
        date: false,
      },
      preview: null,
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    photo: {
      type: Object,
      required: true,
    },
    site_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    preview_file: {
      get() {
        if (this.preview) return this.preview;

        return this.data.path
          ? this.data.path_url
          : `${process.env.VUE_APP_ROOT_API}images/no-image.jpg`;
      },
      set(value) {
        this.preview = value;
      },
    },
    data() {
      return JSON.parse(JSON.stringify(this.photo));
    },
  },
  methods: {
    getPhotoData() {
      let param = {
        id: this.customer.id,
        site_id: this.site_id,
      };
      this.$store.dispatch("PHOTO_GET_ALL_BY_CUSTOMER_ID", param);
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      this.preview_file = URL.createObjectURL(file);

      let reader = new FileReader();
      reader.onloadend = () => {
        this.data.photo = reader.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
}

.form-header {
  color: #828282;
}

.form-text {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
.image_preview {
  object-fit: cover;
}
</style>
