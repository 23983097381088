<template>
  <div class="gray-bg management-blk pa-6 mt-5">
    <div class="management-title mb-4">
      <h3>タスク管理情報</h3>
    </div>
      <div class="management-info">
        <v-row dense>
          <v-col cols="4" class="text-right mng-lft">作成日時</v-col>
          <v-col cols="8" class="pl-3 mng-rgt">{{ task.created_at | yearMonthDateTime }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4" class="text-right mng-lft">作成者</v-col>
          <v-col cols="8" class="pl-3 mng-rgt">{{ task.created_by ? task.created_by.full_name : "-" }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4" class="text-right mng-lft">更新日時</v-col>
          <v-col cols="8" class="pl-3 mng-rgt">{{ task.updated_at | yearMonthDateTime }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4" class="text-right mng-lft">最終更新者</v-col>
          <v-col cols="8" class="pl-3 mng-rgt">{{ task.updated_by ? task.created_by.full_name : "-" }}</v-col>
        </v-row>
        <v-row v-if="task.notified_at" dense>
          <v-col cols="4" class="text-right mng-lft">通知</v-col>
          <v-col cols="8" class="pl-3 mng-rgt">{{ task.notified_at | yearMonthDate }}</v-col>
        </v-row>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  name: "TaskManagementInfo",
  computed: {
    ...mapGetters(["task"])
  },
  filters: {
    yearMonth(date) {
      if (!date) {
        return "-";
      }
      return dayjs(date).format("YYYY/MM");
    },
    yearMonthDate(date) {
      if (!date) {
        return "-";
      }
      return dayjs(date).format("YYYY/MM/DD");
    },
    yearMonthDateTime(date) {
      if (!date) {
        return "-";
      }
      return dayjs(date).format("YYYY/MM/DD HH:mm");
    }
  }
};
</script>

<style lang="scss" scoped>
.gray-bg{
  background: #F4F4F4;
}
.management-blk{

}
.management-title{
  h3{
    color: #666666;
    font-weight: 500;
  }
}
.mng-lft{
  color: #909090;
  font-weight: 500;
}
.mng-rgt{
  color: #909090;
  font-weight: 500;
}
</style>
