<template>
    <TaskList />
</template>

<script>
import TaskList from "@/components/layout/task/TaskList";
export default {
  name: "Tasks",
  components: { TaskList }
};
</script>

<style scoped></style>
