<template>
  <div class="blue-bg work-information py-6 px-4 mt-5">
    <div class="d-flex align-center mb-4 box-subtitle">
      <v-avatar color="#4F55A7" size="10"> </v-avatar>
      <span class="pl-2">作業情報</span>
    </div>
    <div class="premium-blk mb-3">
      {{ coatingGenre }}
    </div>
    <div class="area-blk mb-3">
      {{ task.project.svc_coating.coating_area }} ㎡
    </div>
    <div class="range-blk">
      {{ coatingRange }}
    </div>
    <div class="mt-6 wrk-info">
      <v-row
        dense
        v-for="(field, index) in dataFields"
        :key="`${field.name}-${index}`"
      >
        <template v-if="checkForEmpty(field)">
          <v-col class="wrk-info-lft" cols="4">{{ field.label }}</v-col>
          <v-col class="wrk-info-rgt d-flex flex-wrap" cols="8">
            <template
              v-if="Array.isArray(task.project.svc_coating[field.name])"
            >
              <div
                class="d-flex align-center mr-3 pb-1"
                v-for="(item, subIndex) in task.project.svc_coating[field.name]"
                :key="`${field.name}-${index}-${subIndex}`"
                :class="{ 'mb-4': field.name === 'floor_materials' }"
              >
                <!-- <v-avatar
                  v-if="field.name === 'floor_color'"
                  :key="`${field.name}-${index}-${subIndex}`"
                  :color="getColorObj(item.text).color"
                  size="12"
                >
                </v-avatar> -->
                <template v-if="field.name === 'floor_materials'">
                  <v-avatar
                    class="mr-4"
                    tile
                    min-height="65"
                    min-width="100"
                    style="border-radius: 5% !important;"
                    :color="item.type === 0 ? item.color_code : ''"
                  >
                    <v-img
                      min-height="65"
                      min-width="30"
                      v-if="item.type === 1"
                      :src="item.image_url"
                    />
                  </v-avatar>
                  <div class="mr-4">
                    {{ item.name }}
                    <div v-if="item.pivot.custom_value">
                      ( {{ item.pivot.custom_value }} )
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <template v-if="field.name === 'floor_genre'">
                {{ coatingFloorType }} {{ task.project?.svc_coating?.floor_genre_memo ? ' ('+ task.project?.svc_coating?.floor_genre_memo +')' : '' }}
              </template>
              <template v-else-if="field.name === 'tough_sealer'">
              {{tough_sealers[task.project.svc_coating[field.name]]}}
              </template>
              <template
                v-else-if="
                  field.name === 'assigned_partner' ||
                    field.name === 'partner_workrange'
                "
              >
                <span
                  :class="{
                    'white-space-preline': field.name === 'partner_workrange',
                  }"
                >
                  {{ task.project.svc_coating[field.name] }}
                </span>
              </template>
              <template v-else>
                {{
                  task.project.svc_coating[field.name] === null ? '' :(task.project.svc_coating[field.name] === 1 ? "なし" : "あり")
                }}
                <span
                  v-if="
                    field.name === 'removal_work' &&
                      task.project.svc_coating.removal_work === 0
                  "
                  >{{ task.project.svc_coating.memo }}
                </span>
              </template>
            </template>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["task"]),
    coatingGenre() {
      let genres = [
        "プレミアムコートEX",
        "プレミアムコートEXプラス",
        "プレミアムコートUVプラス",
        "シリコンコーティング",
        "水性コーティング",
        "プレミアムガード",
      ];
      if (typeof this.task.project?.svc_coating?.coating_genre == "number") {
        return genres[this.task?.project?.svc_coating?.coating_genre];
      }
      let coatings = "";
      coatings = this.task.project.svc_coating?.coating_genre
        ?.map((coating) => genres[coating])
        .join(" , ");
      return coatings;
    },
    coatingRange() {
      let coatingRange = [];
      if (this.task.project.svc_coating.coating_genre <= 2) {
        coatingRange = this.task.project.svc_coating.coating_range1;
      } else {
        coatingRange = this.task.project.svc_coating.coating_range2;
      }
      return coatingRange.reduce((a, b, i) => {
        return (
          a +
          `${b.text} ${b.id ? `(${b.text_value})` : ""} ${
            i !== coatingRange.length - 1 ? "/ " : ""
          }`
        );
      }, "");
    },
    coatingFloorType() {
      let types = [
        "3溝突板",
        "2溝突板",
        "1溝タイプ（フラット）",
        "1溝タイプ（ハピアタイプ）",
        "1溝タイプ（木目強調タイプ）",
        "化学床",
      ];

      return types[this.task.project.svc_coating.floor_genre];
    },
  },
  data() {
    return {
      tough_sealers: ['30%', '100%', '150%', 'なし'],
      dataFields: [
        {
          name: "floor_materials",
          label: "床材の色",
        },
        {
          name: "floor_genre",
          label: "床材種類",
        },
        {
          name: 'tough_sealer',
          label: 'タフシーラー'
        },
        {
          name: "removal_work",
          label: "剥離作業",
        },
        {
          name: "micro_mop",
          label: "マイクロモップ",
        },
        {
          name: "premium_cleaner",
          label: "プレミアム専用クリーナー",
        },
        {
          name: "counter_table",
          label: "多目的カウンター",
        },
        {
          name: "assigned_partner",
          label: "外部発注",
        },
        {
          name: "partner_workrange",
          label: "発注範囲",
        },
      ],
      colors: [
        { name: "白", color: "#FFFFFF" },
        { name: "薄茶", color: "#FFDC99" },
        { name: "中間", color: "#FFC34F" },
        { name: "茶", color: "#AE7300" },
        { name: "濃茶", color: "#664300" },
        { name: "グレー", color: "#868686" },
        { name: "緑", color: "#1F9A00" },
        { name: "黒", color: "#333333" },
        { name: "その他", color: "#ffffff" },
        { name: "青", color: "#0000ff" },
      ],
    };
  },
  methods: {
    getColorObj(name) {
      return (
        this.colors.find((color) => color.name === name) ||
        this.colors[this.colors.length - 1]
      );
    },
    checkForEmpty(field) {
      if (Array.isArray(this.task.project.svc_coating[field.name])) {
        return this.task.project.svc_coating[field.name].length ? true : false;
      }
      return this.task.project.svc_coating[field.name] != null ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-bg {
  background: #f8f9ff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
}
.box-subtitle {
  span {
    color: #4f55a7;
    font-weight: 700;
  }
}
.premium-blk {
  color: #333333;
  font-size: 1.125rem;
  font-weight: 700;
}
.area-blk {
  color: #333333;
  font-size: 1.125rem;
  font-weight: 700;
}
.range-blk {
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
}
.wrk-info-lft {
  color: #828282;
  font-size: 0.813rem;
  font-weight: 500;
}
.wrk-info-rgt {
  color: #333333;
  font-weight: 500;
}
.dot {
  height: 66px;
  width: 100px;
  border-radius: 3px;
  border: 1px solid #7e7e7e;
  display: inline-block;
}
.white-space-preline {
  white-space: pre-line;
  overflow: hidden;
}
</style>