<template>
  <div class="mt-5 vehicle-blk">
    <span class="mb-2">車</span>
    <div class="vehicle-info">
      {{ task.car ? task.car.car_class+" "+task.car.number_plate : '-' }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CarDetails",
  computed: {
    ...mapGetters(["task"])
  }
};
</script>

<style lang="scss" scoped>
.vehicle-blk{
  span{
    color: #828282;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;
  }
}
.vehicle-info{
  color: #333333;
  font-size: 0.875rem;
  font-weight: 500;
}
</style>
