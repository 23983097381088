<template>
  <TaskDetails />
</template>

<script>
import TaskDetails from "@/components/tasks/TaskDetails";
export default {
  components: { TaskDetails }
};
</script>

<style></style>
