<template>
  <div>
    <v-card
      class="custome_desing mt-5"
      color="#ffeeee"
      v-if="task.claims.length"
    >
      <v-card-title>
        <div class="d-flex align-center">
          <v-avatar color="#C62E2E" size="10"> </v-avatar>
          <div class="text-subtitle-1 text_color pl-3">クレーム</div>
        </div>
      </v-card-title>
      <v-card-subtitle>
        <template v-if="task.claims.length === 0">
          <v-row>
            <v-col cols="12" class="text-center">
              クレームがありません。
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <div
            v-for="(claim, index) in task.claims"
            :key="`${index}-${claim.date}`"
          >
            <div class="d-flex flex-wrap text-caption mb-3">
              <div class="pr-2">{{ getDateFormat(claim.date) }}</div>

              <v-chip
                color="#8C5F5F"
                text-color="white"
                v-for="(category, index) in claim.categories"
                :key="`${category.name}-${index}`"
                label
                small
                class="mr-3 mb-1 claim-tag"
              >
                {{ category.name }}
              </v-chip>
            </div>
            <div class="d-blcok text-subtitle-2">
              <div class="mt-3">クレーム内容</div>
              <div class="black--text">
                {{ claim.description }}
              </div>
            </div>
            <div class="d-blcok text-subtitle-2">
              <div class="mt-3">対応内容</div>
              <div class="black--text">
                {{ claim.response }}
              </div>
            </div>
            <v-divider
              v-if="index !== task.claims.length - 1"
              color="#E0E0E0"
              class="my-5"
            ></v-divider>
          </div>
        </template>
        <div
          v-if="task.claims.length === 0"
          class="mt-5 align-center d-flex justify-center"
        >
          <span class="text--primary" @click="goToDetail()">
            [ クレーム一覧 ]</span
          >
        </div>
      </v-card-subtitle>
    </v-card>
    <div
      v-if="task.claims_count > 10"
      class="mt-5 align-center d-flex justify-center"
      style="cursor:pointer"
    >
      <span class="text--primary" @click="goToList()"> [ 一覧を見る ]</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["customer"],
  computed: {
    ...mapGetters(["task"]),
    siteId() {
      return this.task.site?.id;
    }
  },
  methods: {
    getDateFormat(claim_date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(claim_date).toLocaleDateString("ja-JP", options);
    },
    goToDetail() {
      this.$router.push("/customer/claim/" + this.customer.id);
    },
    goToList() {
      this.$router.push({
        name: "customer-claim",
        params: {
          customer_id: this.customer.id,
          site_id: this.siteId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text_color {
  color: #c62e2e;
}
</style>
