<template>
  <div class="mt-5">
    <v-dialog ref="addPhotoDialog" v-model="addPhotoDialog" width="500">
      <AddPhotoDialog
        :customer="customer"
        :site_id="siteId"
        :task_id="task.id"
        @close-dialog="addPhotoDialog = false"
      ></AddPhotoDialog>
    </v-dialog>
    <v-dialog
      persistent
      ref="showPhotoDialog"
      v-model="showPhotoDialog"
      width="100%"
    >
      <ShowPhotoDialog
        :customer="customer"
        :site_id="siteId"
        :photo="photo"
        @close-dialog="showPhotoDialog = false"
      ></ShowPhotoDialog>
    </v-dialog>
    <span> 写真</span>
    <!-- TODO: Clarify photos for task -->
    <template v-if="task.photos.length === 0">
      <v-row>
        <v-col cols="12" class="text-center">
          写真がありません。
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row v-for="item in task.photos" :key="item.id" align="center">
        <v-col class="col-6">
          <v-img
            @click="showPhoto(item.id)"
            :src="item.path ? item.path_url : `${root}images/no-image.jpg`"
          ></v-img>
        </v-col>
        <v-col cols="6" class="photo-desc">
          <v-row>
            <v-col cols="12" class="grey--text text-caption pb-0">
              <span class="mr-3">{{ getDateFormat(item.date) }}</span>
              {{ item.updated_by_user ? item.updated_by_user.full_name : "-" }}
            </v-col>
            <v-col cols="12 text-heading-3">
              {{ item.memo }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <div class="mt-5">
      <v-btn @click="addPhotoDialog = true"  color=" #F2F2F2" class="grey--text custome_button">
        <v-icon right dark color="grey">
          mdi-camera
        </v-icon>
        <span class="ml-3"> 画像追加</span>
      </v-btn>
    </div>
    <div @click="goToDetail()" class="d-flex mt-5 justify-center" style="cursor: pointer">
      [ 画像一覧 ]
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShowPhotoDialog from "@/components/layout/customer/photo/ShowPhotoDialog";
import AddPhotoDialog from "@/components/layout/customer/photo/AddPhotoDialog";

export default {
  props: ["customer"],
  components: {AddPhotoDialog, ShowPhotoDialog },
  computed: {
    ...mapGetters(["task"]),
    siteId() {
      return this.task.site?.id;
    }
  },
  data() {
    return {
      root: process.env.VUE_APP_ROOT_API,
      addPhotoDialog: false,
      showPhotoDialog: false,
      photo: null
    };
  },
  methods: {
    getDateFormat(claim_date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(claim_date).toLocaleDateString("ja-JP", options);
    },
    goToDetail() {
      this.$router.push({
        name: "customer-photos",
        params: {
          customer_id: this.customer.id,
          site_id: this.siteId
        }
      });
    },
    showPhoto(id) {
      this.photo = this.task.photos.find(c => c.id == id);
      this.showPhotoDialog = true;
    }
  }
};
</script>

<style scoped>
.custome_desing {
  max-height: 100px;
  max-width: 150px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}
.color_grey {
  color: #666666;
}
.color_black {
  color: #333333;
}
.custome_button {
  width: 100% !important;
  background: #f2f2f2;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  height: 46px;
}
</style>
