<template>
  <div>
    <div class="d-flex justify-end">
      <div class="id-no">T{{ task.id }}</div>
    </div>
    <div class="d-flex align-center mb-1">
      <span class="task_date pr-3">日付 / チーム / メモ</span>
      <v-divider color="#4F55A7"></v-divider>
    </div>
    <div class="d-flex justify-space-between align-center">
      <span class="date-time-blk">
        {{ task.date }}（{{ getDateFormat(task.date) }}）
        {{ task.task_start_time }} 開始
        <span v-if="task && task.task_end_time">
          {{ task.task_end_time }} 完了時刻
        </span>
        <!-- <span class="Add time" v-if="!task.task_end_time" @click="task_end_time_picker = !task_end_time_picker">入力する</span>
      <v-icon v-if="task.task_end_time" @click="task_end_time_picker = !task_end_time_picker" size="18">mdi-pencil</v-icon> -->
    
    <v-time-picker
     v-if="task_end_time_picker"
      v-model="task.task_end_time"
      class=""
      format="24hr"
      scrollable
      min=""
      max=""
    >
    <v-btn @click="updateTaskFinishTime">Save</v-btn>
    </v-time-picker>
      </span>

      <v-avatar
        class="service-circle"
        :color="task.service.background_color"
        size="41"
      >
        <span
          class="service-name text-truncate1"
          :style="{ color: task.service.font_color }"
        >
          {{ task.service.name }}
        </span>
      </v-avatar>
      <!--      <v-avatar color="#F2C94C" size="41">-->
      <!--        <span style="font-size: 8px">コート</span>-->
      <!--      </v-avatar>-->
    </div>
    <div class="d-flex gather-time">
      <span class="pr-3"> 集合 </span>
      <span class="pr-3"> 時間 </span>
      <span class="pr-1"> {{ task.gathering_time }} </span>
      <span class="pr-1">@</span> <span> {{task.gathering_place}}</span>
    </div>

     
    <div class="mt-4" v-if="weatherInfo">
      <v-row dense align="center" class="mb-1">
        <v-col cols="auto">
          <v-avatar :class="weatherBackgroundClass">
            <v-img :src="weatherInfo" max-width="60" contain />
          </v-avatar>
        </v-col>
        <v-col cols="auto" class="ml-2">
          {{ weatherDescription }}
        </v-col>
      </v-row>
      <div class="weather-last-update">最終更新：{{ weatherLastUpdate }}</div>
    </div>
    <div class="mt-5 participant-blk">
      <span>参加メンバー</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "TaskDetails",
  data() {
    return {
      task_end_time_picker: false
    };
  },
  computed: {
    ...mapGetters(["task"]),
    weatherDescription() {
      if (!this.task.weather_info) return "";
      return this.$t(`weather_${this.task.weather_info.weather[0].id}`);
    },
    weatherInfo() {
      if (!this.task.weather_info) return "";
      return `http://openweathermap.org/img/wn/${this.task.weather_info.weather[0].icon}@4x.png`;
    },
    weatherLastUpdate() {
      if (!this.task.weather_last_update) return "";
      return dayjs(this.task.weather_last_update).format("YYYY-MM-DD HH:mm");
    },
    weatherBackgroundClass() {
      if (!this.task.weather_info) return "";
      return `background-${this.task.time === 0 ? "day" : "night"}`;
    },
  },
  methods: {
    getDateFormat(claim_date) {
      return dayjs(claim_date).format(`dd`);
    },
     async updateTaskFinishTime(){
      let params = {}
      params.id = this.task.id
      params.task_end_time = this.task.task_end_time
       this.task_end_time_picker = false
       await this.$store.dispatch('UPDATE_TASK_END_TIME', {params})
    }
  },
};
</script>

<style lang="scss" scoped>
.service-circle {
  height: 76px !important;
  min-width: 76px !important;
  max-width: 76px;
  width: auto !important;
}
.service-circle .service-name {
  max-width: 80px;
  padding: 5px;
}
.id-no {
  color: #828282;
  font-size: 0.75rem;
}
.task_date {
  font-weight: 700;
  font-size: 0.875rem;
  color: #4f55a7;
}
.date-time-blk {
  font-size: 1.125rem;
  color: #333333;
  font-weight: 700;
}
.service-name {
  font-size: 0.75rem;
  font-weight: 500;
  max-width: 36px;
  display: block;
}
.gather-time , .finish-time {
  span {
    font-size: 0.938rem;
    color: #828282;
    font-weight: 500;
  }
}
.participant-blk {
  span {
    color: #828282;
  }
}

.weather-last-update {
  font-size: 0.7rem;
}

.background {
  &-day {
    background: rgb(87, 165, 253);
    background: linear-gradient(
      0deg,
      rgba(201, 238, 255, 1) 0%,
      rgba(87, 165, 253, 1) 100%
    );
  }

  &-night {
    background: rgb(145, 148, 201);
    background: linear-gradient(
      0deg,
      rgba(225, 226, 239, 1) 0%,
      rgb(145, 148, 201, 1) 100%
    );
  }
}
</style>
