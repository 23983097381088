<template>
  <v-container class="info-box mt-4 pa-5">
    <v-row>
      <v-col cols="12" class="info-box-headline">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="mr-4" color="#4F55A7" size="10"
              >mdi-checkbox-blank-circle</v-icon
            >
            アフター
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="flex-grow-1">
        <template v-for="(item, index) in coatingAfters">
          <v-row no-gutters :key="`after-service-1-${item.id}`" align="center">
            <v-col cols="6" class="info-box-date text-heading-3">
              {{ item.date | formatDate }}
            </v-col>
            <v-col cols="6" class="info-box-date text-right text-heading-3">
              <span class="label"> 部屋番号：</span>{{ item.room_number }}
            </v-col>
            <v-col cols="12" class="info-box-date text-right text-heading-3">
              <span class="label"> 対応種別：</span
              >{{ item.is_paid ? paidStatus[item.is_paid] : "" }}
            </v-col>

            <v-col cols="12">
              <v-row
                no-gutters
                :key="`after-service-2-${item.id}`"
                class="mb-3"
              >
                <v-col cols="3" class="info-box-date text-heading-3">
                  <span class="label"> 依頼項目 </span>
                </v-col>

                <v-col cols="9" class="info-box-date text-heading-3">
                  {{ showText(item.reason) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                  <span class="label"> 対応方法 </span>
                </v-col>

                <v-col cols="9" class="info-box-date text-heading-3 mtop-7">
                  {{ showText(item.method) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                  <span class="label"> 対応結果 </span>
                </v-col>

                <v-col cols="9" class="info-box-date text-heading-3 mtop-7">
                  {{ showText(item.after_treatment) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3">
                  <span class="label"> 対応者 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date white-space-prewrap text-heading-3"
                >
                  {{ showUser(item.users) }}
                </v-col>

                <v-col cols="3" class="info-box-date text-heading-3 mtop-7">
                  <span class="label"> 対応初見 </span>
                </v-col>

                <v-col
                  cols="9"
                  class="info-box-date white-space-prewrap text-heading-3 mtop-7"
                >
                  {{ item.memo }}
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="index !== coatingAfters.length - 1"
              cols="12"
              class="mb-3"
            >
              <v-divider />
            </v-col>
          </v-row>
        </template>
        <div @click="goToList()" v-if="coatingAftersCount > 10" class="text-center list-link info-box-date">[ 一覧を見る ] </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "AfterServiceList",
  props: ["customer"],
  computed: {
    ...mapGetters(["task", "allClaimCategories"]),
    coatingAfters() {
      return this.task?.project?.svc_coating?.coating_after;
    },
    coatingAftersCount(){
      return this.task?.project?.svc_coating?.coating_afters_count
    },
    siteId() {
      return this?.task?.site?.id
    },
    customerId() {
      return this?.task?.customer?.id
    }
  },
  data() {
    return {
      paidStatus: {
        0: "無償",
        1: "有償"
      },
      afterServiceDialog: false,
      afterServiceItem: {}
    };
  },
  filters: {
    formatDate(date) {
      if (!date) return "-";
      return dayjs(date).format("YYYY年M月D日");
    }
  },
  methods: {
    showText(array) {
      if (!array?.length) return "-";
      return array
        .map(
          item =>
            `${item.text || item.name} ${
              item.extra_type === "with_text"
                ? ` (${item.text_value || item.value})`
                : ""
            }`
        )
        .join(", ");
    },
    showUser(array) {
      if (!array?.length) return "-";
      return array
        .map(c => `${c.last_name || ""} ${c.first_name || ""}`)
        .join(", ");
    },
    goToList() {
    this.$router.push({
      name: 'customer-after-service',
      params: {
        customer_id: this.customerId,
        site_id: this.siteId
      }
    })
    }
  }
};
</script>

<style lang="scss" scoped>
.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  .white-space-prewrap {
    white-space: pre-wrap;
  }
  .info-box-date {
    .label {
      color: #828282;
    }
  }
  .date {
    font-size: 20px !important;
  }
  &-headline {
    font-size: 18px;
    color: #4f55a7;
    font-weight: 700;
  }
  .mtop-7 {
    margin-top: 7px;
  }
  font-size: 16px;

  &-date {
    color: #333;
  }

  &-title {
    color: #878787;
  }

  &-content {
    color: #333333;
    word-break: break-all;
  }
}
.list-link {
  cursor: pointer;
  font-weight: 500;
}
</style>
