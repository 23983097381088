<template>
  <TasksCard />
</template>

<script>
import TasksCard from "@/components/tasks/List";
export default {
  name: "Tasks",
  components: { TasksCard }
};
</script>

<style scoped></style>
