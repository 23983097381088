<template>
  <div class="blue-bg guard-blk py-6 px-4 mt-5" v-if="task.project.svc_coating.premium_guard.length">
    <div class="d-flex align-center mb-4 box-subtitle">
      <v-avatar color="#4F55A7" size="10"> </v-avatar>
      <span class="pl-2">プレミアムガード</span>
    </div>
    <div class="guard-info">
      <v-row>
        <v-col
          cols="auto"
          v-for="(item, index) in task.project.svc_coating.premium_guard"
          :key="`premium-guard-${index}`"
        >
          <span>{{ item.text }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PremiumGuard",
  computed: {
    ...mapGetters(["task"])
  }
};
</script>

<style lang="scss" scoped>
.guard-blk {
  background: #F8F9FF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
}
.box-subtitle{
  span{
    color: #4F55A7;
    font-weight: 700;
  }
}
.guard-info{
  span{
    color: #333333;
    font-weight: 500;
  }
}
</style>
