<template>
  <v-card elevation="0" color="#F9F9F9" class="bg-blk pa-4">
    <v-row no-gutters class="mb-2">
      <v-col cols="6" class="pr-2">
        <v-select background-color="#ffffff" class="form-text" dense hide-details outlined :items="years"
          v-model="formData.setYear" id="YearSelect"></v-select>
      </v-col>
      <v-col cols="6" class="pl-2">
        <v-select background-color="#ffffff" class="form-text" dense hide-details outlined :items="months"
          v-model="formData.setMonth" id="monthSelect">
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <v-text-field background-color="#ffffff" class="form-text" v-model="formData.setCustSiteName" dense hide-details
          outlined placeholder="顧客・現場名"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn block color="#ABCFFF" class="font-weight-bold" @click="search">検索</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "TaskFilter",
  data() {
    return {
      formData: {
        setYear: (new Date().getFullYear()) + "年",
        setMonth: (new Date().getMonth() + 1) + "月",
        setCustSiteName: null
      }
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      const yearArray = Array.from(
        { length: 2 },
        (value, index) => (year - index) + "年"
      ).sort(function (a, b) {
        return b - a;
      });
      return yearArray;
    },
    months() {
      const month = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      return month;
    }
  },
  methods: {
    async search() {
      this.$emit('Loading', true)
      let parms = {}
      parms.setYear = this.formData?.setYear?.split("年")?.[0];
      parms.setMonth = this.formData.setMonth.split("月")?.[0];
      parms.setCustSiteName = this.formData.setCustSiteName;
      await this.$store.dispatch("TASKS_FILTER", parms).then(result => {
        if (result.status == 200) {
          this.$emit('Loading', false)
        }
      });
    }
  },
  created() {
    this.search();
  }
};
</script>

<style lang="scss" scoped>
.bg-blk {
  border: 1px solid #BDBDBD !important;
  border-radius: 5px !important;
}

.form-text {
  font-size: 0.875rem;
}
</style>
