<template>
  <v-card>
  <validation-observer ref="observer">
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            写真管理
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="text-center pb-0">
            <input
              type="file"
              ref="file"
              style="display: none"
              accept="image/png, image/gif, image/jpeg"
              @change="onFilePicked"
            />

            <img
              :src="preview_file"
              width="100%"
              height="300"
              class="image_preview mb-2"
            />
          </v-col>
          

          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <span style="color: #AD4545" class="text-center mr-3">
                - 写真削除
              </span>
              <span style="color: #336ABB" class="text-center" @click="$refs.file.click()">
                + 画像アップロード
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="form-header mb-2">
              実施日
            </div>
            <div>
              <v-dialog
                ref="dialogBirthDay"
                v-model="modal.date"
                :return-value.sync="data.date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider 
                     v-slot="{errors}"
                     name="photoDate"
                     rules="required">
                  <v-text-field
                    v-model="data.date"
                    readonly
                    class="form-text"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder="日付選択"
                  ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  :day-format="formatDatePicker"
                  locale="ja"
                  v-model="data.date"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.date = false">
                    キャンセル
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogBirthDay.save(data.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">
              説明文
            </div>
            <div>
            <validation-provider
                  v-slot="{ errors }"
                  name="photoDescription"
                  rules="required"
                >
              <v-textarea
                class="form-text"
                dense
                outlined
                placeholder="画像の説明文"
                hide-details="auto"
                :error-messages="errors"
                :error="errors.length !== 0"
                v-model="data.memo"
              ></v-textarea>
              </validation-provider>
              <div class="text-right">
                {{ data.memo.length }} / {{ descriptionMaxLength }} 文字
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-row align="center">
              <v-col cols="6"> </v-col>
              <v-col cols="6" class="text-right">
                <v-col cols="auto">
                  <v-btn
                    @click="save"
                    color="#4F55A7"
                    class="white--text"
                    width="100"
                    :loading="loading"
                    >登録
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    </validation-observer>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "AddPhotoDialog",
  data() {
    return {
      descriptionMaxLength: 500,
      data: {
        photo: "",
        date: "",
        memo: ""
      },
      modal: {
        date: false
      },
      preview: null,
      loading: false
    };
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    },
    task_id: {
      type: Number,
      required: false
    }
  },
  computed: {
    preview_file: {
      get() {
        return this.preview
          ? this.preview
          : process.env.VUE_APP_ROOT_API + "images/no-image.jpg";
      },
      set(value) {
        this.preview = value;
      }
    }
  },
  methods: {
    save() {
       this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
      this.loading = true;
      let data = new FormData();
      data.append("customer_id", this.customer.id);
      data.append("site_id", this.site_id);
      for (const [key, value] of Object.entries(this.data)) {
        data.append(key, value ? value : value == 0 ? 0 : null);
      }

      this.$store
        .dispatch("PHOTO_CREATE", data)
        .then(
          response => {
            if (response.data.status == "success") {
              let param = {
                photo: {
                  id: this.customer.id,
                  customer_id: this.customer.id,
                  site_id: this.site_id,
                  paginate: 10
                }
              };
              if (this.$route.name === 'tasksDetails') {
                this.$store.dispatch("GET_TASK_BY_ID", { id: this.task_id });
              } else {
                this.$store.dispatch("PHOTO_GET_ALL_BY_CUSTOMER_ID", param.photo);
              }
              this.$emit("close-dialog");
              this.preview = null
              this.data.photo = "";
              this.data.date = "";
              this.data.memo = "";

            }
          },
          error => {
            this.$swal.fire(
              "Something wrong",
              "Oops! something wrong, please check and try again",
              "error"
            );
            throw error;
          }
        )
        .finally(() => (this.loading = false));
       })
    },
    formatDatePicker(e) {
      return dayjs(e).format("D");
    },
    onFilePicked(e) {
      this.data.photo = e.target.files[0];
      this.preview_file = URL.createObjectURL(this.data.photo);
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
}

.form-header {
  color: #828282;
}

.form-text {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
.image_preview {
  object-fit: cover;
}
</style>
