<template>
  <v-card class="custome_desing mt-5" color="#F8F9FF" v-if="checkForEmptyBox()">
    <v-card-title>
      <div class="d-d-block">
        <div class="d-flex align-center">
          <v-avatar color="#4F55A7" size="10"> </v-avatar>
          <div class="text-caption pl-3 custome_color">
            作業情報
          </div>
        </div>
        <div class="text-subtitle-1 custome_font_weight pt-3">
          カーペット洗浄
        </div>
      </div>
    </v-card-title>
    <v-card-subtitle class="mt-3">
      <table class="custome_table">
        <tr
          v-for="(field, index) in dataFields"
          :key="`${field.name}-${index}`"
        >
          <template v-if="field.name === 'divider'">
            <td colspan="2">
              <v-divider class="my-4"></v-divider>
            </td>
          </template>
          <template v-else>
            <template v-if="checkForEmptyArray(field)">
            <td>{{ field.label }}</td>
            <td class="custome_td">
              <template
                v-if="Array.isArray(task.project.svc_carpet[field.name])"
              >
                <template
                  v-for="(item, subIndex) in task.project.svc_carpet[
                    field.name
                  ]"
                >
                  <v-avatar
                    v-if="field.name === 'pat'"
                    :key="`${field.name}-${index}-${subIndex}`"
                    :color="getColorObj(item.text).color"
                    size="12"
                  >
                  </v-avatar>
                  {{ item.text }}
                </template>
              </template>
              <template v-else>
                {{
                  task.project.svc_carpet[field.name] === 1 ? "あり" : "なし"
                }}
              </template>
            </td>
          </template>
          </template>
        </tr>
      </table>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["task"])
  },
  data() {
    return {
      dataFields: [
        {
          name: "prep",
          label: "作業前工程"
        },
        {
          name: "pre_task",
          label: "前処理作業"
        },
        {
          name: "washing",
          label: "洗浄作業"
        },
        {
          name: "machine",
          label: "使用バルチャー"
        },
        {
          name: "stain_removal",
          label: "染み抜き"
        },
        {
          name: "wastewater",
          label: "汚水回収"
        }
      ],
      colors: [
        { name: '白', color: '#FFFFFF' },
        { name: '薄茶', color: '#FFDC99' },
        { name: '中間', color: '#FFC34F' },
        { name: '茶', color: '#AE7300' },
        { name: '濃茶', color: '#664300' },
        { name: 'グレー', color: '#868686' },
        { name: '緑', color: '#1F9A00' },
        { name: '黒', color: '#333333' },
        { name: 'その他', color: '#ffffff' },
        { name: '青', color: '#0000ff' }
      ]
    };
  },
  methods: {
    getColorObj(name) {
      return (
        this.colors.find(color => color.name === name) ||
        this.colors[this.colors.length - 1]
      );
    },
    checkForEmptyArray(field) {
      if(Array.isArray(this.task.project.svc_carpet[field.name])){
        return this.task.project.svc_carpet[field.name].length ? true : false
      }
      return this.task.project.svc_carpet[field.name] ? true : false
    },
    checkForEmptyBox(){
      let display = false
      this.dataFields.forEach((field) => {
        if(Array.isArray(this.task.project.svc_carpet[field.name])){
          if(this.task.project.svc_carpet[field.name].length){
            display = true
          }
        }
        else {
          if(this.task.project.svc_carpet[field.name]){
            display = true
          }
        }
      })
      return display
    }
  }
};
</script>

<style scoped>
.custome_desing {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.custome_td {
  color: #333333;
}
.custome_color {
  color: #4f55a7 !important;
}
.custome_font_weight {
  font-weight: bold;
}
.custome_table {
  width: 100%;
}
</style>
