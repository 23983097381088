<template>
  <div class="mt-12">
    <div class="d-flex align-center mb-4">
      <div class="task-date pr-3">
        顧客 / 台帳
      </div>
      <v-divider color="#4F55A7"></v-divider>
    </div>
    <v-card color="#fffff0" elevation="0" class="customer-blk pb-4">
      <v-card-title class="mb-8">
        <v-row>
          <v-col cols="12">
            <h3 class="customer-title mb-1">顧客情報</h3>
            <div v-if="task.customer.furigana_name" class="name-small mb-1">
              <span>{{ task.customer.furigana_name }}</span>
            </div>
            <div class="name-big">
              <span>{{ task.customer.name }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle v-if="customerAddress || task.customer.phone1 || task.customer.memo">
        <v-row dense v-if="task.customer.address">
          <v-col cols="3" class="customer-info-lft">住所</v-col>
          <v-col cols="9" class="customer-info-rgt">
            <a
              class="address"
              :href="'https://maps.google.com/?q=' + customerAddress"
              target="_blank"
            >
              {{ customerAddress }}
              <v-icon size="20">$newTab</v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3" class="customer-info-lft">電話</v-col>
          <v-col cols="9" class="customer-info-rgt">{{
            task.customer.phone1
          }}</v-col>
        </v-row>
        <v-row dense v-if="task.customer.memo">
          <v-col cols="3" class="customer-info-lft">メモ</v-col>
          <v-col cols="9" class="customer-info-rgt white-space-pre">
            {{ task.customer.memo || "-" }}
          </v-col>
        </v-row>

        <v-divider v-if="customerAddress || task.customer.phone1 || task.customer.memo" color="#EFF0D4" class="mt-10"></v-divider>
      </v-card-subtitle>
      <v-card-title class="mb-2">
        <div class="d-flex justify-space-between">
          <h3 class="customer-title">物件/現場の基本情報</h3>
        </div>
      </v-card-title>
      <v-card-subtitle v-if="task.site.name || task.site.furigana_name || task.service.name || siteAddress || task.site.file_pdf">
        <v-row dense v-if="task.site.name">
          <v-col cols="3" class="customer-info-lft">物件名</v-col>
          <v-col cols="9" class="customer-info-rgt">{{ task.site.name }}</v-col>
        </v-row>
        <v-row dense v-if="task.site.furigana_name">
          <v-col cols="3" class="customer-info-lft">物件名（かな）</v-col>
          <v-col cols="9" class="customer-info-rgt">{{ task.site.furigana_name }}</v-col>
        </v-row>
        <v-row dense v-if="task.service.name">
          <v-col cols="3" class="customer-info-lft">サービス</v-col>
          <v-col cols="9" class="customer-info-rgt">{{
            task.service.name
          }}</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3" class="customer-info-lft">所在地</v-col>
          <v-col cols="9" class="customer-info-rgt">
            <a
              class="address"
              :href="'https://maps.google.com/?q=' + siteAddress"
              target="_blank"
            >
              {{ siteAddress }}
              <v-icon size="20">$newTab</v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row v-if="task.site.file_pdf" dense>
          <v-col cols="3" class="customer-info-lft">PDF</v-col>
          <v-col cols="9" class="customer-info-rgt">
            <a :href="task.site.file_pdf_url" target="_blank" class="mr-2 blue--text text-decoration-underline">{{task.site.file_pdf_name}}</a>
          </v-col>
        </v-row>

        <v-divider v-if="task.site.name || task.site.furigana_name || task.service.name || siteAddress || task.site.file_pdf" color="#EFF0D4" class="mt-10"></v-divider>
      </v-card-subtitle>
      <v-card-subtitle v-if="project.partner_0 || project.partner_2 || project.on_site_monitoring || project.partner_1">
        <v-row dense v-if="project.partner_0">
          <v-col cols="4" class="customer-info-lft">メーカー</v-col>
          <v-col cols="8" class="customer-info-rgt">
            {{ project.partner_0.name }}
          </v-col>
        </v-row>
        <v-row dense v-if="project.partner_2">
          <v-col cols="4" class="customer-info-lft">発注会社</v-col>
          <v-col cols="8" class="customer-info-rgt">
            {{ project.partner_2.name }}
          </v-col>
        </v-row>
        <v-row dense v-if="project.partner_1">
          <v-col cols="4" class="customer-info-lft">元請会社</v-col>
          <v-col cols="8" class="customer-info-rgt">
            {{ project.partner_1.name }}
          </v-col>
        </v-row>
        <v-row dense v-if="project.on_site_monitoring !== null">
          <v-col cols="4" class="customer-info-lft">現場立合</v-col>
          <v-col cols="8" class="customer-info-rgt">
            {{ detailInfo.on_site_monitoring[project.on_site_monitoring] ? detailInfo.on_site_monitoring[project.on_site_monitoring] : '-' }}
          </v-col>
        </v-row>

        <v-divider v-if="project.partner_0 || project.partner_2 || project.on_site_monitoring || project.partner_1" color="#EFF0D4" class="mt-10"></v-divider>
      </v-card-subtitle>
      <v-card-subtitle>
        <template v-for="itemInfo in filteredItemInfos">
          <v-row dense :key="`row-${itemInfo.keys}`">
            <v-col cols="4" class="customer-info-lft">
              {{ itemInfo.title }}
            </v-col>
            <v-col cols="8" class="customer-info-rgt">
              <template v-if="Number.isInteger(project[itemInfo.keys])">
                {{ detailInfo[itemInfo.keys][project[itemInfo.keys]] }}
              </template>
              <template v-else v-for="item in project[itemInfo.keys]">
                {{ item.text }}
              </template>
            </v-col>
          </v-row>
        </template>
        <v-divider color="#EFF0D4" class="my-5"></v-divider>
        <v-row dense v-if="project.general_guideline">
          <v-col cols="4" class="customer-info-lft">
            現場に関するメモ
          </v-col>
          <v-col cols="8" class="customer-info-rgt text-pre-wrap">
            {{ project.general_guideline || '-' }}
          </v-col>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CustomerAccount",
  computed: {
    ...mapGetters(["task"]),

    customerAddress() {
      if (
        !this.task.customer.prefecture?.name &&
        !this.task.customer.city &&
        !this.task.customer.address
      )
        return "-";

      return `${this.task.customer.prefecture?.name || ""}${this.task.customer
        .city || ""}${this.task.customer.address || ""}`;
    },

    siteAddress() {
      if (
        !this.task.site.prefecture?.name &&
        !this.task.site.city &&
        !this.task.address
      )
        return "-";
      return `${this.task.site.prefecture?.name || ""}${this.task.site.city ||
        ""}${this.task.site.address || ""}`;
    },

    project() {
      return this.task.site?.projects.find(
        project => project.id === this.task.project_id
      );
    },

    filteredItemInfos() {
      return this.itemInfos.filter(itemInfo => {
        if (this.project[itemInfo.keys] !== null) return itemInfo;
      });
    }
  },
  methods: {
    openFilePDF(url) {
      window.open(url, '_blank');
    },
    limitStr(string, limit) {
      let str = string;

      if (typeof str === 'string' && str.length > limit) {
        str = str.slice(0, limit) + '...';
      }
      
      return str;
    },
  },
  data() {
    return {
      itemInfos: [
        { title: "駐車場", keys: "parking", has_extra: true },
        { title: "ブレーカー", keys: "lightening_braker" },
        { title: "鍵", keys: "key", is_checkbox: true },
        { title: "電源", keys: "outlet" },
        { title: "新築/中古", keys: "site_age" },
        { title: "異常", keys: "electrical_issues", has_extra: true },
        { title: "入居者", keys: "residents" },
        { title: "シャッター", keys: "shutter" },
        { title: "家具類", keys: "furnitures" },
        { title: "カーテン", keys: "curtain" },
        { title: "エアコン", keys: "air_conditioner" }
      ],
      detailInfo: {
        elevator: ["あり", "なし"],
        parking: ["敷地内", "コインパーキング", "近隣"],
        lightening_braker: ["入", "切"],
        key: ["立ち合い", "キーバンカー", "その他対応"],
        electrical_issues: ["あり", "なし"],
        site_age: ["新築", "中古"],
        outlet: ["本設", "仮設"],
        residents: ["あり", "なし"],
        shutter: ["あり", "なし"],
        furnitures: ["あり", "なし"],
        curtain: ["あり", "なし"],
        on_site_monitoring: ["あり", "なし"],
        air_conditioner: ["あり", "使用", "不使用", "不明", "なし"]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.task-date {
  color: #4f55a7;
  font-weight: 700;
}
.customer-blk {
  border-radius: 5px;
  border: 1px solid #cfd180 !important;
}
.customer-title {
  color: #3a3c00;
  font-weight: 600;
  font-size: 0.875rem;
}
.name-small {
  color: #333333;
  font-size: 0.625rem;
  line-height: normal;
}
.name-big {
  color: #333333;
  font-size: 1.125rem;
  line-height: normal;
}
.customer-info-lft {
  font-size: 0.813rem;
  color: #757900;
  font-weight: 500;
}
.customer-info-rgt {
  font-size: 0.875rem;
  color: #333333;
  font-weight: 500;
}
.address {
  text-decoration: none;
  color: #000;
}
.cm-add-btn{
  border-radius: 8px !important;
  color: #4F55A7;
  font-weight: 700;
  border: 1px dashed #4F55A7;
}
.white-space-pre {
  white-space: pre-line;
  word-break: break-all;
}
</style>
