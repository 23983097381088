<template>
  <div>
    <div class="mt-5 mb-3 d-flex align-center">
      <div class="pr-3 gnrl-staff">一般社員</div>
      <v-divider color="#4F55A7"></v-divider>
    </div>
    <div class="d-flex flex-wrap">
      <div
          class="pr-1 mb-2 text-center"
          v-for="(member, index) in taskMembers"
          :key="`member-${member.full_name}-${index}`"
      >
        <v-avatar size="51" :class="isLeader(member) ? 'active' : ''">
          <img :src="getUserPhoto(member)" :alt="`${member.full_name}-avatar`" />
        </v-avatar>
        <div class="text-truncate avatar-txt">
          {{ member.last_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["task"]),
    taskMembers() {
      let members = [];
      if (this.task.leader) {
        members.push(this.task.leader);
      }
      members.push(
        ...this.task.task_member.filter(
          member => member.id !== this.task.leader_id
        )
      );
      return members;
    }
  },
  data() {
    return {
      users: [
        {
          name: "高山",
          avatar: "https://picsum.photos/250/300?image=661"
        },
        {
          name: "森田",
          avatar: "https://picsum.photos/250/300?image=663"
        },
        {
          name: "高山",
          avatar: "https://picsum.photos/250/300?image=664"
        },
        {
          name: "森田",
          avatar: "https://picsum.photos/250/300?image=665"
        }
      ]
    };
  },
  methods: {
    getUserPhoto(member) {
      return member.profile_image_url || require("@/assets/images/userblank.png")
    },
    isLeader(member) {
      return this.task.leader_id === member.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  border: 3px solid #f2994a;
}
.avatar-txt {
  max-width: 36px;
  display: block;
  margin: 0 auto;
  font-size: 0.75rem;
  line-height: normal;
}
.gnrl-staff {
  color: #4f55a7;
}
</style>
