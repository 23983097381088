<template>
  <v-card class="custome_desing mt-5" color="#F8F9FF">
    <v-card-title>
      <div class="d-d-block">
        <div class="d-flex align-center">
          <v-avatar color="#4F55A7" size="10"> </v-avatar>
          <div class="text-caption pl-3 custome_color">
            作業情報
          </div>
        </div>
        <div class="text-subtitle-1 custome_font_weight pt-3">
          共用部洗浄 床洗浄ワックス
        </div>
      </div>
    </v-card-title>
    <v-card-subtitle class="mt-3">
      <table class="custome_table">
        <tr v-for="(field, index) in dataFields" :key="`${field.name}-${index}`">
          <template v-if="field.name === 'divider'">
            <td colspan="2">
              <v-divider class="my-4"></v-divider>
            </td>
          </template>
          <template v-else>
            <td v-if="field.label == 'ワックス塗布階数'">範囲指定</td>
            <td v-if="field.label == 'ワックス塗布階数'">
              <span
                style="color: #333333 !important; line-height: 2"
                v-html="getWixingValue(task.project.svc_floor)"
              />
            </td>
            <td v-if="field.label != 'ワックス塗布階数'">{{ field.label }}</td>
            <td class="custome_td d-flex flex-wrap" v-if="field.label != 'ワックス塗布階数'">
              <template v-if="Array.isArray(task.project.svc_floor[field.name])">
                <div class="d-flex align-center mr-3 pb-1" :key="`${field.name}-${index}-${subIndex}`"
                  v-for="(item, subIndex) in task.project.svc_floor[field.name]">
                  <!-- <v-avatar
                    v-if="field.name === 'pat'"
                    :key="`${field.name}-${index}-${subIndex}`"
                    :color="getColorObj(item.text).color"
                    size="12"
                  >
                  </v-avatar> -->

                  <v-avatar
                    v-if="field.name === 'pat'"
                    class="mr-4"
                    tile
                    min-height="65"
                    min-width="100"
                    style="border-radius: 5% !important; border: 1px solid #7e7e7e"
                    :color="getColorObj(item.text).color"
                  >
                  </v-avatar>
                  {{ item.text }}
                </div>
              </template>
              <template v-else>
                {{ task.project.svc_floor[field.name] === 1 ? "あり" : "なし" }}
              </template>
            </td>
          </template>
        </tr>
      </table>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["task"])
  },
  data() {
    return {
      dataFields: [
        {
          name: "prep",
          label: "清掃前準備"
        },
        {
          name: "detergent",
          label: "使用洗剤"
        },
        {
          name: "detergent_method",
          label: "洗剤塗布方法"
        },
        {
          name: "cleaning_method",
          label: "基本洗浄方法"
        },
        {
          name: "polisher",
          label: "使用ポリッシャー"
        },
        {
          name: "cleaning_machine",
          label: "使用自洗機"
        },
        {
          name: "pat",
          type: "color",
          label: "使用パット"
        },
        {
          name: "certain_place_method",
          label: "場所指定洗浄方法"
        },
        {
          name: "certain_place_explanation",
          label: "指定場所"
        },
        {
          name: "divider"
        },
        {
          name: "waxing_place",
          label: "ワックス塗布階数"
        },
        {
          name: "waxing_product",
          label: "使用ワックス"
        },
        {
          name: "waxing_rule",
          label: "注意事項"
        }
      ],
      colors: [
        { name: '白', color: '#FFFFFF' },
        { name: '薄茶', color: '#FFDC99' },
        { name: '中間', color: '#FFC34F' },
        { name: '茶', color: '#AE7300' },
        { name: '濃茶', color: '#664300' },
        { name: 'グレー', color: '#868686' },
        { name: '緑', color: '#1F9A00' },
        { name: '黒', color: '#333333' },
        { name: 'その他', color: '#ffffff' },
        { name: '青', color: '#0000ff' }
      ]
    };
  },
  methods: {
    getColorObj(name) {
      return (
        this.colors.find(color => color.name === name) ||
        this.colors[this.colors.length - 1]
      );
    },
    getWixingValue(floor) {
      let waxing_place_text = ''
      
      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_all)) {
        waxing_place_text += '全体：'
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_all_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_all) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction && floor.waxing_place.waxing_place_all) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_all + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_all
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_1)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分1: '
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_1_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_1) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_1) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_1 + ")"
          } else {
            waxing_place_text +=floor.waxing_place?.waxing_place_part_1
          }        
        }
      } 

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_2)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分2: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_2_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_2) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_2) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_2 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_2
          }        
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_3)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分3: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_3_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_3) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction && floor.waxing_place.waxing_place_part_3) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_3 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_3
          }        
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_4)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分4: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_4_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_4) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction && floor.waxing_place.waxing_place_part_4) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_4 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_4
          }        
        }
      }
      return waxing_place_text
    },
  }
};
</script>

<style scoped>
.custome_desing {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.custome_td {
  color: #333333;
}

.custome_color {
  color: #4f55a7 !important;
}

.custome_font_weight {
  font-weight: bold;
}

.custome_table {
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #7e7e7e;
  display: inline-block;
}

.square {
  height: 20px;
  width: 20px;
  border-radius: 20%;
  display: inline-block;
}
</style>
