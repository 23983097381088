<template>
  <div class="mt-8 normal-memo-blk">
    <label class="mb-2 d-block">通常メモ</label>
    <div class="normal-memo-info">
      <p class="mb-0">{{ task.memo }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NormalMemo",
  computed: {
    ...mapGetters(["task"])
  }
};
</script>

<style lang="scss" scoped>
  .normal-memo-blk{
    label{
      color: #828282;
      font-weight: 500;
    }
  }
  .normal-memo-info{
    p{
      color: #333333;
      white-space: pre-wrap;
    }
  }
</style>
