<template>
  <v-row class="d-none">
    <v-col class="custome_padding">
      <v-list two-line>
        <template>
          <v-list-item class="p-0 bg_color">
            <v-avatar size="30" class="header_avatar">
              <v-icon color="#ffffff">mdi-keyboard-backspace</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="header_title pl-3"
                >高山 さんのタスク
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon class="header_icon"> mdi-view-sequential</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "TaskDetailsHeader"
};
</script>

<style lang="scss" scoped>
.custome_dropdown_padding {
  padding-right: 85px;
}
.header_avatar {
  background-color: #4f55a7;
  width: 500px;
  height: 500px;
}
.mdi-view-sequential::before {
  content: "\F728";
  color: #2f80ed;
}
.header_title {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
.header_subtitle {
  font-family: Noto Sans CJK JP;
  font-size: 14px;
  color: #4f4f4f;
}
.custome_padding {
  padding: 0px;
}
.bg_color {
  background-color: #f2f2f2;
}
</style>
