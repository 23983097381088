<template>
  <div class="mt-8 important-memo-blk">
    <template v-if="task.important_notice">
      <label class="mb-2 d-block">重要連絡</label>
      <div class="important-memo-info">
        <p class="mb-0">{{ task.important_notice }}</p>
      </div>
    </template>
    <v-row no-gutters class="mt-8 mb-4">
      <v-col cols="12" class="pb-4" v-if="checkLeader()">
        <v-btn
          block
          large
          color="#F2C94C"
          class="font-weight-bold"
          @click="makeAttendance(0)"
        >
          <v-icon left size="25" class="mr-3" color="#000000">
            mdi-account-clock </v-icon
          >勤怠 イン・アウト
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          large
          color="#F2C94C"
          class="font-weight-bold"
          @click="makeAttendance(2)"
        >
          <v-icon left size="25" class="mr-3" color="#000000"> mdi-car </v-icon
          >運転 & 調整 イン・アウト
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ImportantMemo",
  computed: {
    ...mapGetters(["task", "user", "worktimes"]),
  },
  methods: {
    checkLeader() {
      return this.task.leader_id == this.user.id ? true : false;
    },
    makeAttendance(type) {
      let worktime =
        this.worktimes.filter((wt) => {
          if (wt.type_id == type && wt.user_id == this.user.id) {
            if (type == 2 && wt.clock_out !== null) {
              return false;
            }
            return true;
          }
        })[0] || null;

      if (worktime) {
        return this.$router.push({
          name: "clockOut",
          params: { worktime_id: worktime.id, type: type },
        });
      } else {
        return this.$router.push({
          name: "clockIn",
          params: { task_id: this.task.id },
          query: { type: type },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .important-memo-blk{
    label{
      color: #828282;
      font-weight: 500;
    }
  }
  .important-memo-info{
    p{
      color: #920000;
      white-space: pre-wrap;
    }
  }
</style>
