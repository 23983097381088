<template>
  <div class="mt-5">
    <div class="d-flex align-center">
      <div class="pr-3 subcontractor">外注先</div>
      <v-divider color="#4F55A7"></v-divider>
    </div>
    <span class="text-subtitle-1 text--primary">
      <v-row>
        <v-col cols="auto" class="text-heading-3">
          <template v-if="task.task_freelancer_users.length">
            <v-row no-gutters dense>
              <v-col
                cols="12"
                v-for="outsource in task.task_freelancer_users"
                :key="outsource.name"
                class="text-center"
              >
                <v-avatar size="40" class="my-3">
                  <img
                    alt="avatar"
                    :src="getPartnerProfilePicture(outsource)"
                  />
                </v-avatar>
                <div class="participant-name">
                  {{ outsource.name }}
                  <span>
                    ({{ outsource.pivot.freelancers_count }})
                  </span>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            -
          </template>
        </v-col>
      </v-row>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Subcontractor",
  computed: {
    ...mapGetters(["task"]),
    outsourceStaffCount() {
      return this.task.task_freelancer_users.reduce((a, b) => {
        return a + b.pivot.freelancers_count;
      }, 0);
    }
  },
  methods: {
    getPartnerProfilePicture(partner) {
      if (partner.profile_image) return `${partner.profile_image_url}`;
      return require("@/assets/images/userblank.png");
    }
  }
};
</script>

<style lang="scss" scoped>
.subcontractor {
  color: #4f55a7;
}
</style>
