<template>
  <div class="mx-auto">
    <v-row no-gutters class="mb-4">
      <v-col cols="12" class="header">
        <h2>{{ $t("my_task_list") }}</h2>
      </v-col>
    </v-row>
    <TaskFilter @Loading="isLoading" />

    <!--<v-col v-for="task in alltasks" :key="task.id">
      <TaskCards
        :task="task"
        :tempDate="getTemp(task.date)"
        :dayTotalTask="getTotalDayTasks()"
        :nightTotalTask="getTotalNightTasks()"
      />
    </v-col>-->
    <template v-if="loading">
      <v-skeleton-loader type="list-item-three-line, card-heading, list-item-avatar, divider" class="mt-3">
      </v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line, card-heading, list-item-avatar, divider" class="mt-3">
      </v-skeleton-loader>
      <v-skeleton-loader type="list-item-three-line, card-heading, list-item-avatar, divider" class="mt-3">
      </v-skeleton-loader>
    </template>
    <TaskCards :tasks="sortedTasks" v-if="!loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
import TaskCards from "@/components/layout/task/TaskCards";
import TaskFilter from "@/components/layout/task/TaskFilter";
export default {
  name: "TaskList",
  data() {
    return {
      tempdate: "",
      loading: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      }
    };
  },
  components: { TaskCards, TaskFilter },
  computed: {
    ...mapGetters(["alltasks"]),
    sortedTasks() {
      if (!this.alltasks) return [];
      let tasks = [...this.alltasks];
      return tasks.sort((a, b) => {
        let dateA = dayjs(`${a.date} ${a.task_start_time}`);
        let dateB = dayjs(`${b.date} ${b.task_start_time}`);
        return dateA.isBefore(dateB) ? -1 : 1;
      });
    }
  },
  methods: {
    isLoading(e) {
      this.loading = e
    }
    // ...mapActions(["USER_TASKS"])
  },
  created() {
    // this.USER_TASKS();
  }
};
</script>

<style lang="scss" scoped>
.header {
  h2 {
    color: #828282;
    font-size: 0.87rem;
    font-weight: 500;
  }
}
</style>
