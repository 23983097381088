<template>
  <CardBase :help-button="false" :showDate="false">
    <template class="base_card" v-slot:content-body>
      <TaskDetailsHeader />
      <TaskDetails />
      <TaskStaff v-if="task.task_member.length" />
      <Subcontractor v-if="task.task_freelancer_users.length"/>
      <CarDetails v-if="task.car" />
      <Gasoline v-if="task.car" />
      <NormalMemo v-if="task.memo" />
      <ImportantMemo />
      <CustomerAccount />
      <template v-if="task.service.id === 0">
        <WorkInformation />
        <PremiumGuard />
        <CampaignService />
        <ServiceConstruction />
        <!--      <AfterSalesSupport />-->
      </template>
      <template v-else-if="task.service.id !== 4">
        <FloorInformation />
        <WindowInformation />
        <CarpetInformation />
      </template>
      <AfterService v-if="task.project.svc_coating" />
      <Claim v-else :customer="task.customer" />
      <Photos :customer="task.customer" />
      <TaskManagementInfo />
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TaskDetailsHeader from "@/components/ui/task/taskDetails/TaskDetailsHeader";
import TaskDetails from "@/components/ui/task/taskDetails/TaskDetails.vue";
import TaskStaff from "@/components/ui/task/taskDetails/TaskStaff.vue";
import Subcontractor from "@/components/ui/task/taskDetails/Subcontractor";
import CarDetails from "@/components/ui/task/taskDetails/CarDetails";
import Gasoline from "@/components/ui/task/taskDetails/Gasoline";
import NormalMemo from "@/components/ui/task/taskDetails/NormalMemo";
import ImportantMemo from "@/components/ui/task/taskDetails/ImportantMemo";
import CustomerAccount from "@/components/ui/task/taskDetails/CustomerAccount";
import WorkInformation from "@/components/ui/task/taskDetails/WorkInformation";
import PremiumGuard from "@/components/ui/task/taskDetails/PremiumGuard";
import CampaignService from "@/components/ui/task/taskDetails/CampaignService";
import ServiceConstruction from "@/components/ui/task/taskDetails/ServiceConstruction";
// import AfterSalesSupport from "@/components/ui/task/taskDetails/AfterSalesSupport";
import Claim from "@/components/ui/task/taskDetails/Claim";
import Photos from "@/components/ui/task/taskDetails/Photos";
import TaskManagementInfo from "@/components/ui/task/taskDetails/TaskManagementInfo";
import { mapGetters } from "vuex";
import FloorInformation from "@/components/ui/task/taskDetails/FloorInformation";
import WindowInformation from "@/components/ui/task/taskDetails/WindowInformation";
import CarpetInformation from "@/components/ui/task/taskDetails/CarpetInformation";
import AfterService from "@/components/ui/task/taskDetails/AfterService";
export default {
  components: {
    AfterService,
    CarpetInformation,
    WindowInformation,
    FloorInformation,
    CardBase,
    TaskDetailsHeader,
    TaskDetails,
    TaskStaff,
    Subcontractor,
    CarDetails,
    Gasoline,
    NormalMemo,
    ImportantMemo,
    CustomerAccount,
    WorkInformation,
    PremiumGuard,
    CampaignService,
    ServiceConstruction,
    // AfterSalesSupport,
    Claim,
    Photos,
    TaskManagementInfo
  },
  computed: {
    ...mapGetters(["task"])
  },
  created() {
    this.getTaskById();
  },
  methods: {
    async getTaskById() {
      let id = this.$route.params.id;
      await this.$store.dispatch("GET_TASK_BY_ID", { id: id });
      await this.$store.dispatch("WORKTIME_BY_TASK_ID_GET", this.task.id);
    }
  }
};
</script>

<style></style>
