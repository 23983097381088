<template>
  <div class="mt-6" v-if="this.tasks.length != 0">
    <v-row dense class="mb-6" v-for="task in this.tasks" :key="task.id">
      <v-col v-if="getTemp(task.date)" cols="12">
        <v-row align="center" no-gutters>
          <v-col class="date-txt">{{ getDateFormat(task.date) }}（{{getDayFormat(task.date)}}）</v-col>
          <v-col class="text-right item-txt">
            <span style="margin-right:5px">昼:{{ getTotalDayTasks(task.date) }}件</span >
            <span>夜:{{ getTotalNightTasks(task.date) }}件</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="tasks-list">
        <router-link :to="{ name:'tasksDetails', params: { id: task.id }}">
          <v-card
            class="tasks-sec pa-4"
            :style="{
              backgroundColor: task.service.background_color,
              color: task.service.font_color
            }"
          >
            <v-row>
              <v-col cols="12">
                <h2 class="task-title mb-1">
                  <i
                    v-if="Date.now() <= new Date(task.date)"
                    aria-hidden="true"
                    class="v-icon notranslate mdi mdi-circle theme--light mr-2"
                  ></i>
                  <span v-if="task.customer">{{ task.customer.name }}</span>
                </h2>
                <div class="property-cls mb-1" v-if="task.site">
                <label class="pr-3" :style="{color: task.service.font_color}">物件名</label>
                  <span class="property_id">{{task.site.site_id}}</span>
                  <span class="property_name">{{task.site.name}}</span>
                </div>
                <p class="task-time mb-1">
                  <span>{{task.task_start_time}}</span>
                </p>
                <ul class="task-services pl-0 mb-3">
                  <li>
                    <span :style="{color: task.service.font_color}" v-if="task.service">{{
                      task.service.name
                    }}</span>
                    <!--- <span v-if="task.service">{{ task.service.name }}</span>-->
                  </li>
                </ul>
              </v-col>
            </v-row>
            <TaskShowMember :text_color="task.service.font_color" :member="task.task_member" :leader_id="task.leader_id" />
          </v-card>
        </router-link>   
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row dense class="mb-6">
      <v-col style="text-align:center;margin-top: 15px;" class="no-data">表示するデータがありません。</v-col>
    </v-row>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import TaskShowMember from '@/components/layout/customer/task/TaskShowMember'
export default {
  name: "TaskCards",
  components: {TaskShowMember},
  data() {
    return {
      members: ["高山", "森田", "田中", "高山", "森田", "田中"],
      root: process.env.VUE_APP_ROOT_API,
      tempDate:true,
    };
  },
  props: {
    tasks: [Object, Array],
  },
  methods: {
    getDayFormat(task_date) {
      return dayjs(task_date).format(`dd`)
    },
    getDateFormat(create_at) {
      return dayjs(create_at).format(`YYYY年MM月DD日`)
    },
    getTemp(task_created_at) {
      if (document.readyState == "complete") {
        //let created_at = task_created_at.split("T");

      /*
        let dateObj = new Date(created_at[0]);
        let month = dateObj.getUTCMonth() + 1;
        let year = dateObj.getUTCFullYear();

        let created_year_month = year + "-" + month;
        console.log(created_year_month);
        */
        if (this.tempdate != task_created_at) {
          this.tempdate = task_created_at;
          return true;
        } else {
          return false;
        }
      }
    },
    getTotalDayTasks(taskDate) {
      return this.tasks.filter(task => task.date === taskDate && task.time === 0).length
    },
    getTotalNightTasks(taskDate) {
      return this.tasks.filter(task => task.date === taskDate && task.time === 1).length
    }
  },
};
</script>
<style lang="scss" src="./TaskCards.scss" scoped></style>
